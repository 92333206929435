<template lang="pug">
  SignUpForm
</template>
<script>
import SignUpForm from '@/components/SignUpForm'
export default {
  components: {
    SignUpForm
  }
}
</script>
