import { render, staticRenderFns } from "./App.vue?vue&type=template&id=3fc518a2&lang=pug&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VAppBar,VBottomNavigation,VBtn,VIcon,VImg,VList,VListItem,VListItemTitle,VMenu,VOverlay,VProgressCircular,VSelect,VSheet,VSpacer,VTab,VTabs,VToolbarTitle})
